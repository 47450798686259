<template>
    <div>
        <div class="develop-body container-fluid">
            <div class="develop-body-title">全体的な紹介</div>
            <span>01</span>
            <div class="develop-body-desc">
                <div class="develop-body-desc-desc" >
                    <!-- <div class="blue-background"></div> -->
                    <div style="width:100%">
                        <div style="width:110px;border-bottom:4px solid #0262FF;margin-top:40px;"></div>
                        <p>私どものもつITパートナーネットワークと共に技術サービスの総合力でお客様(一般企業、SIer、IT関連企業など)のITサービス各専門分野を支援します。私どもは国内外の先鋭的でユニークなパートナーに「提案力」という付加価値を加え、お客様のニーズに沿ったITサービスを提供します。</p>
                    </div>
                    <div style="z-index:1">
                        <img src="@/assets/images/develop/img_kaifa1.png" alt="">
                    </div>
                </div>
            </div>
        </div>
         <div class="develop-body container-fluid" style="background:#FFF">
            <div class="develop-body-title">業務範囲</div>
            <span>02</span>
            <div class="develop-body-desc-2">
                <ExpandBanner :data='expandBannerData' />
            </div>
        </div>
         <div class="develop-body container-fluid" style="padding:0;margin-bottom:50px;">
            <div class="develop-body-desc-3">
                <div >
                   <img src="@/assets/images/develop/img_kaifa2.png" alt="">
                </div>
                <div class="develop-body-desc-3-body">
                    <div class="develop-body-title">個人事業者</div>
                    <span>03</span>
                    <div class="develop-body-desc-3-desc">
                        企業から独立したフリーランスIT技術者(IT個人事業者)とプロフェッショナルパートナーとして契約を結び、技術者には安定した案件の提供を、お客様にはその技術者の専門性の高い能力を提供します。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExpandBanner from '@/components/ExpandBanner'
export default {
    components:{
        ExpandBanner
    },
    data(){
        return {
            expandBannerData:[
                {
                    index:0,
                    number:'01',
                    title:'技術・開発ビジネス',
                    desc:'業務に精通した知識、先進性を追求し続けるシステム技術、プロジェクトを推進する管理能力で、お客様の求めるシステム構築を支援し、プロジェクトがかえる特有な問題を知見・技術力・開発力をもつ組織、リソース、製品でソリューション提供します。',
                },
                {
                    index:1,
                    number:'02',
                    title:'情報サービスビジネス',
                    desc:'企業の情報システム部門を支援サービスを目的とした、ACCESSシステムの開発・修正や、実績・統計情報のデータ分析などの構築分析業務。利用者からのPC操作の問い合わせ、その他不具合などを対応するヘルプデスク。注文に関する問い合わせ・受付、データエントリー業務などの事務処理。PCキッティング作業などを提供します。',
                },
                {
                    index:2,
                    number:'03',
                    title:'保守・運用ビジネス',
                    desc:'インフラシステム・基幹系業務アプリケーションの運用、設備の維持管理など、保守・運用サービスなど、それぞれのIT環境を支えます。'
                },
                {
                    index:3,
                    number:'04',
                    title:'グローバルビジネス',
                    desc:'日本国内のビジネスにとどまらず、アジア特に中国の提携会社による低コストと日本品質を準拠したオフショア開発と、中国のお客様(日本企業)に向け、日本で培った技術力を中国パートナーと共にネイティブな日本語でのITサービスを提供します。'
                }
            ]
        }
    }
}
</script>

<style>
@media screen and (min-width:768px) and (max-width:1920px) {
   .flex{
        display: flex;
    }
    .develop-body{
        background-color: #F5F5F9;
        padding: 0 calc(100vw/1920*360);
        padding-bottom: 40px;
        z-index: 1;
    }
    .develop-body-title{
        font-size:calc(100vw/1920*24);
        padding-top: 45px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:700;
        color:rgba(2,98,255,1);
        line-height:33px;
    }
    .develop-body>span{
        width:89px;
        height:89px;
        font-size:80px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .develop-body-desc-3-body{
        width: calc(100vw/1920*380);
        padding-left: calc(100vw/1920*40);
        padding-top: calc(100vw/1920*200);
    }
    .develop-body-desc-3-body>span{
        width:89px;
        height:89px;
        font-size:80px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .develop-body-img{
        width: calc(100vw/1920*460);
    }
    .develop-body-desc{
        /* width:1200px; */
        /* height:480px; */
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .develop-body-desc-title{
        text-align: left;
        font-size:calc(100vw/1920*18);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
        
    }
    .develop-body-desc-desc{
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        /* margin-top: 20px; */
    }
    .develop-body-desc-desc p{
        font-size:calc(100vw/1920*16);;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*32);;
        margin-top: 30px;
        text-align: left;
        width: calc(100vw/1920*500);
    }
    .develop-body-desc-desc img{
        margin: -7px;
        width: calc(100vw/1920*460);
        height: calc(100vw/1920*320);
    }
    .blue-background{
        position: absolute;
        width: calc(100vw/1920*500);
        height: calc(100vw/1920*360);
        background: #0262FF;
        left: calc(100vw/1920*1118);
        /* bottom: calc(-100vw/1920*00); */
        z-index: 0;
        
    }
    .develop-body-desc-3{
        display: flex;
        justify-content: flex-start;
        
    }
    .develop-body-desc-3 img{
        width: calc(100vw/1920*1119);
    }
    .develop-body-desc-3-desc{
        text-align: left;
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*32);
    } 
}
@media screen and (max-width:768px) {
    .flex{
        display: flex;
    }
    .develop-body{
        background-color: #F5F5F9;
        padding: 20px;
        padding-bottom: 40px;
        z-index: 1;
    }
    .develop-body-title{
        font-size:12px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:700;
        color:rgba(2,98,255,1);
        line-height:33px;
    }
    .develop-body>span{
        width:89px;
        height:89px;
        font-size:40px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .develop-body-desc-3-body{
        /* width: calc(100vw/1920*380); */
        padding: 20px;
        /* padding-top: calc(100vw/1920*200); */
    }
    .develop-body-desc-3-body>span{
        width:89px;
        height:89px;
        font-size:40px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .develop-body-img{
        width: 200px;
    }
    .develop-body-desc{
        /* width:1200px; */
        /* height:480px; */
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .develop-body-desc-title{
        text-align: left;
        font-size:calc(100vw/1920*18);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
        
    }
    .develop-body-desc-desc{
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        /* margin-left: 20px; */
        /* margin-top: 20px; */
    }
    .develop-body-desc-desc p{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(102,102,102,1);
        /* line-height:14px; */
        margin-top: 30px;
        padding: 10px;
        text-align: left;
        /* width: calc(100vw/1920*500); */
    }
    .develop-body-desc-desc img{
        /* margin-left: -14px; */
        width: 80vw;
        /* height: calc(100vw/1920*320); */
    }
    .blue-background{
        position: absolute;
        width: calc(100vw/1920*500);
        height: calc(100vw/1920*360);
        background: #0262FF;
        left: calc(100vw/1920*1118);
        /* bottom: calc(-100vw/1920*00); */
        z-index: 0;
        
    }
    .develop-body-desc-3{
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        
    }
    .develop-body-desc-3 img{
        width: 90vw;
        padding-bottom: 20px;
    }
    .develop-body-desc-3-desc{
        text-align: left;
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(51,51,51,1);
        /* line-height:14px; */
    }
}
@media screen and (min-width:1920px) {
   .flex{
        display: flex;
    }
    .develop-body{
        background-color: #F5F5F9;
        padding: 0 360px;
        padding-bottom: 40px;
        z-index: 1;
    }
    .develop-body-title{
        font-size:24px;
        padding-top: 45px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:700;
        color:rgba(2,98,255,1);
        line-height:33px;
    }
    .develop-body>span{
        width:89px;
        height:89px;
        font-size:80px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .develop-body-desc-3-body{
        width: 380px;
        padding-left: 40px;
        padding-top: 200px;
    }
    .develop-body-desc-3-body>span{
        width:89px;
        height:89px;
        font-size:80px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .develop-body-img{
        width: 460px;
    }
    .develop-body-desc{
        /* width:1200px; */
        /* height:480px; */
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .develop-body-desc-title{
        text-align: left;
        font-size:18px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:25px;
        
    }
    .develop-body-desc-desc{
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        /* margin-top: 20px; */
    }
    .develop-body-desc-desc p{
        font-size:16px;;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:32px;;
        margin-top: 30px;
        text-align: left;
        width: 500px;
    }
    .develop-body-desc-desc img{
        margin: -7px;
        width: 460px;
        height: 320px;
    }
    .blue-background{
        position: absolute;
        width: 500px;
        height: 360px;
        background: #0262FF;
        left: 1118px;
        /* bottom: calc(-100vw/1920*00); */
        z-index: 0;
        
    }
    .develop-body-desc-3{
        display: flex;
        justify-content: flex-start;
        
    }
    .develop-body-desc-3 img{
        width: 1119px;
    }
    .develop-body-desc-3-desc{
        text-align: left;
        font-size:16px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:32px;
    } 
}
</style>